<template>
  <div class="plan" :planName="`${plan}_${mode}`">
    <div class="isCurrentPlan" v-if="isCurrentPlan">
      <p class="currentPlan_label" >{{$t('Profile.subscription.plans.current_plan')}}</p>
    </div>
    <slot name="title"></slot>
    
    <template v-if="couponLifetimeValide">
      <h3 class="offer" style="margin: 0px; margin-top: 15px;">{{$t('Profile.subscription.limited_offer')}}</h3>
      <h3 class="offer" style="margin-top: 0px;">{{ $t("Profile.subscription.lifetime_price") }}</h3>
      <!--
        <h3 class="old_price" style="font-size: 16px; margin: 0; margin-top: 0px;"> 89 € / {{$t('Profile.subscription.plans.month')}} </h3> 
      -->
    </template>

    <template v-else>
      <h3 v-if="mode == 'monthly'">{{price_monthly}}<a> / {{$t('Profile.subscription.plans.month')}}</a></h3>
      <h3 v-if="mode == 'monthly'" class="billed_yearly">{{$t('Profile.subscription.plans.no_commitment')}}</h3>
      <h3 v-else>{{price_yearly}}<a> / {{$t('Profile.subscription.plans.month')}}</a></h3>
      <h3 v-if="mode == 'yearly'" class="billed_yearly">{{price_yearly_billed}} {{$t('Profile.subscription.plans.billed_yearly')}}</h3>
    </template>


    <h4 class="credit_minutes">{{ credit_minutes }} minutes</h4>
   
    <!-- Features -->
    <br/>
    <div class="features">
      <slot name="features"></slot>
    </div>

    <div class="couponContainer" ref="couponContainer" v-if="!couponLifetimeValide && plan == 'starter'">
      <p style="font-weight: 500; margin-bottom: 8px;">{{ $t('Profile.subscription.discount_code') }}</p>
      <input type="text" placeholder="XXXXX" v-model="coupon" v-on:keyup.enter="validateCoupon">
      <span v-show="coupon !== ''" @click="validateCoupon">{{ $t('Apply') }}</span>
    </div>

    <template v-if="plan != 'free'">
      <p class="button center subscribe" v-if="isCurrentPlan && !couponLifetimeValide" @click="openBillingPortal">{{$t('Modify')}}</p>
      <p class="button center subscribe" v-else-if="currentPlan != null" @click="openBillingPortal">{{$t('Choose')}}</p>
      <p class="button center subscribe" v-else @click="subscribe">{{$t('Choose')}}</p>
    </template>
  
  </div>
</template>


<script>
String.prototype.removeAccents = function() {
    var set = this.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    return set;
};

import Vuex from "vuex";

export default {
  data () {
    return {
      pricings : {
        free : {
          credit_minutes : 15,
          monthly : "0",
          yearly : "0",
          yearly_billed : "0"
        },
        starter : {
          credit_minutes : 60,
          monthly : "89",
          yearly : "89",
          yearly_billed : "1068"
        },
        unlimited : {
          credit_minutes : 600,
          monthly : "249",
          yearly : "249",
          yearly_billed : "2990"
        }
      },
      coupon : "",
      couponLifetimeValide : false
    }
  },
  props: {
    currentPlan: { type: String, default: null },
    mode : { default : "monthly"},
    plan : { default : ""},
    color : {}
  },
  computed : {
    ...Vuex.mapGetters(["getCurrentUser"]),
    isCurrentPlan(){
      if (this.currentPlan != null){
        return this.currentPlan.toLowerCase().replace(/_monthly|_yearly|_lifetime/, "") === this.plan.toLowerCase().replace(/_monthly|_yearly|_lifetime/)
      } else if (this.plan.toLowerCase() == "free"){
        return true
      } else {
        return false
      }
    },

    price_unit(){
      let unit = "€"
      if (this.$i18n.locale == 'fr'){
        unit = " €"
      }
      return unit
    },
    price_monthly(){
      return this.$t('Profile.subscription.plans.currency', {price : this.pricings[this.plan]?.monthly})
    },
    price_yearly(){
      return this.$t('Profile.subscription.plans.currency', {price : this.pricings[this.plan]?.yearly})
    },
    price_yearly_billed(){
      return this.$t('Profile.subscription.plans.currency', {price : this.pricings[this.plan]?.yearly_billed})
    },
    credit_minutes(){
      return this.pricings[this.plan]?.credit_minutes
    },
    discount_code(){
      return this.pricings[this.plan]?.discount_code
    }
  },
  methods: {
    ...Vuex.mapActions(["openBillingPortal"]),
    subscribe() {
      let plan_name = `${this.plan}_${this.mode.toLowerCase()}`
      const redirectUrl = `${process.env.API_URL.replace(/\/$/,"")}/subscribe/${plan_name}?usr_id=${this.getCurrentUser._id}${this.couponLifetimeValide ? '&lifetime' : ''}`;

      console.log(redirectUrl)
      window.location.href = redirectUrl;
    },
    validateCoupon(){
      this.couponLifetimeValide = this.coupon.toLocaleUpperCase() == "ILOVEYOPBOX"
      if (!this.couponLifetimeValide){
        this.coupon = ""
        this.$refs.couponContainer.classList.add('shake');
        setTimeout(() => {
          this.$refs.couponContainer.classList.remove('shake');
        }, 1000);
      }
    }
  },
};
</script>

<style src='./plan.scss' lang="scss" scoped></style>